import moment from "moment";
import { store } from "../store";

export function convertMMDDHHMMFomat(value) {
  try {
    const current = new Date(value).toISOString();
    const splitDate = current.split("T");
    const date = splitDate[0].split("-");
    const time = splitDate[1].split(":");
    const convertDate = `${date[1]}/${date[2]} ${time[0]}:${time[1]}`;
    return convertDate;
  } catch (err) {
    return "-";
  }
}

export function convertDate(value) {
  try {
    const { app } = store.getState();
    const { systemOptions, commonDetails } = app;
    let utcHour = 0;
    console.log(systemOptions);

    if (Object.keys(systemOptions).length > 0) {
      const timezone = commonDetails?.timeZones?.find(
        (timezone) =>
          timezone.timezoneId === parseInt(systemOptions?.timeZone || 0) // systemOptions?.timeZone
      );
      if (Object.keys(timezone || {}).length > 0) {
        const utc = timezone.utc.split("-")[1].split(":");
        utcHour = -+parseInt(parseInt(utc[0] || 0) || 0);
      }
    }

    console.log(utcHour);
    // console.log(commonDetails);
    const date = new Date(value).toISOString();
    const convertDate = moment
      .utc(value)
      .subtract(utcHour, "hour")
      .format("MM/DD/YYYY HH:mm:ss");
    return convertDate;
  } catch (err) {
    return "-";
  }
}
