import http from "./http.service";
import config from "./constant";
import { saveAs } from "file-saver";
import UserService from "./user.service";
import { store } from "../store";
const getCommonDetails = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(config.Common.commonDetails, reqParams);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getLookupDetails = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(
      UserService.getClientId() === "99999"
        ? config.Lookups.llAdminLookUpList
        : config.Lookups.lookUpList,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const fetchSegments = async (params) => {
  try {
    const response = await http.get(config.Lookups.fetchSegments, {});
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const postLookUp = async (params) => {
  try {
    const response = await http.post(
      UserService.getClientId() === "99999"
        ? config.Lookups.llAdminLookUpAdd
        : config.Lookups.lookUpAdd,
      params
    );

    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const addSegment = async (params) => {
  try {
    const response = await http.post(config.Lookups.addSegment, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const addLookupCode = async (params) => {
  try {
    const response = await http.post(config.Lookups.addLookUpCode, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPartnerType = async (params) => {
  try {
    const response = await http.get(config.Common.getPartnerType, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const addClaim = async (params) => {
  try {
    const response = await http.post(config.Lookups.addClaim, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getClaimList = async (params) => {
  try {
    const response = await http.get(config.Lookups.getClaims, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const deleteAttachment = async (params) => {
  try {
    const response = await http.delete(config.Lookups.getClaims, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const viewAttachment = async (obj) => {
  try {
    const reqParams = {
      ...obj,
    };
    const response = await http.post(
      config.Common.viewAttachment,
      reqParams,
      {},
      {
        responseType: "blob",
      }
    );

    const blob = new Blob([response.data], {
      type: obj.fileType,
    });
    saveAs(blob, obj.name);
    return { status: response.status, data: [] };
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

const getAuditInfo = async (params) => {
  try {
    const response = await http.post(config.Common.auditInfo, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getNotifyevent = async (poId) => {
  try {
    const response = await http.get(
      `${config.Common.notifyevent}/PO/${poId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllColumnGrid = async (params) => {
  try {
    const response = await http.get(config.Common.getAllColumnGrid, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const fetchVendorLoactions = async () => {
  try {
    const response = await http.get(config.CarrierRates.vendorLocations, {});
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const updateGridConfig = async (gridConfig) => {
  try {
    const response = await http.put(
      `${config.Common.updateColumnGridById}${gridConfig.gridId}/id/${gridConfig.id}`,
      gridConfig
    );
    return { state: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getGridConfigByGridId = async (gridId) => {
  try {
    const response = await http.get(
      `${config.Common.updateColumnGridById}${gridId}`
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const createGridConfig = async (gridConfig) => {
  try {
    const response = await http.post(
      config.Common.createGridConfig,
      gridConfig
    );
    return { state: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllGropus = async () => {
  try {
    const response = await http.get(config.Shipping.getAllGroups, {});
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const createZone = async (params) => {
  try {
    const response = await http.post(config.Shipping.createZone, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const addZipCode = async (zoneId, params) => {
  try {
    const response = await http.post(
      `${config.Shipping.addZipCode}/${zoneId}`,
      params
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const updateZipCode = async (zoneId, params) => {
  try {
    const response = await http.put(
      `${config.Shipping.updateZipCode}/${zoneId}`,
      params
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const addGroup = async (params) => {
  try {
    const response = await http.post(config.Shipping.addGroup, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const addOrUpdateZoneMap = async (grpId, params) => {
  try {
    const response = await http.put(
      `${config.Shipping.addOrUpdateZoneMap}/${grpId}`,
      params
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const deleteZone = async (zoneId) => {
  try {
    const response = await http.delete(
      `${config.Shipping.deleteZone}/${zoneId}`
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const deleteZip = async (zipId) => {
  try {
    const response = await http.delete(`${config.Shipping.deleteZip}/${zipId}`);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const updateZone = async (params) => {
  try {
    const response = await http.put(config.Shipping.updateZoneName, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const deleteGroup = async (grpId) => {
  try {
    const response = await http.delete(
      `${config.Shipping.deleteGroup}/${grpId}`
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getSearchInitState = async (searchId) => {
  try {
    const response = await http.get(
      `${config.Common.getSearchInitState}/${searchId}`
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getBoldBITokenVal = async () => {
  try {
    const response = await http.get(`${config.BoldBI.getBoldBIToken}`);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const CommonService = {
  getCommonDetails,
  getLookupDetails,
  postLookUp,
  fetchSegments,
  addSegment,
  addLookupCode,
  getPartnerType,
  addClaim,
  getClaimList,
  viewAttachment,
  getAuditInfo,
  getNotifyevent,
  getAllColumnGrid,
  fetchVendorLoactions,
  getAllGropus,
  createZone,
  addZipCode,
  addGroup,
  addOrUpdateZoneMap,
  deleteZone,
  deleteZip,
  updateGridConfig,
  createGridConfig,
  getAllGropus,
  updateZone,
  deleteGroup,
  getSearchInitState,
  updateZipCode,
  getBoldBITokenVal,
  getGridConfigByGridId,
};

export default CommonService;
