import React, { useCallback, useRef, useState, useEffect } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Edit,
  Inject,
  Page,
  Resize,
  Reorder,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { Button } from "antd";
import CommonService from "../../services/common.service";
import moment from "moment";
import { pageOptions } from "../../components/grid/config";
import { convertDate } from "../../services/date.service";

export default function AuditLogTable(props) {
  const { auditlogModalVisible } = props;
  let gridInstance = useRef(null);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (auditlogModalVisible) {
      getAuditInfo();
    }
  }, [auditlogModalVisible]);

  const getAuditInfo = useCallback(async () => {
    try {
      const reqParams = {
        sourceId: props.sourceId,
        sourceType: props.sourceType,
      };
      const response = await CommonService.getAuditInfo(reqParams);
      if (response.status === 200) {
        setDataSource(response.data);
      }
    } catch (error) {}
  }, []);

  const auditDateTemplate = (args) => {
    return (
      <>
        <span title={convertDate(args?.createdDate)}>
          {/* {moment(args?.createdDate).format("MM/DD/YYYY hh:mm:ss")} */}
          {convertDate(args?.createdDate)}
        </span>
      </>
    );
  };
  const logDescTemplate = (args) => {
    return (
      <>
        <span title={args.description}>{args.description}</span>
      </>
    );
  };

  return (
    <div className="row">
      <div className="col-12" style={{ marginTop: "5px" }}>
        <GridComponent
          ref={(g) => (gridInstance = g)}
          dataSource={dataSource}
          gridLines="Both"
          rowHeight={36}
          width="100%"
          height="250px"
          allowPaging={true}
          pageSettings={pageOptions}
          allowReordering={true}
          allowResizing={true}
          allowSorting={true}
          allowTextWrap={true}
          textWrapSettings={{ wrapMode: "Content" }}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="createdDate"
              headerText="Date"
              width="25"
              textAlign="Left"
              template={auditDateTemplate}
            />
            <ColumnDirective
              field="companyName"
              headerText="Company"
              width="20"
              textAlign="Left"
            />
            <ColumnDirective
              field="userName"
              headerText="User Name"
              width="20"
              textAlign="Left"
            />
            <ColumnDirective
              field="description"
              headerText="LOG NOTIFICATION"
              width="60"
              textAlign="Left"
              template={logDescTemplate}
            />
          </ColumnsDirective>
          <Inject services={[Page, Resize, Reorder, Sort]} />
        </GridComponent>
      </div>
      <div className="col-12" align="right" style={{ marginTop: "15px" }}>
        <Button type="primary" onClick={() => props.setAuditlogVisibleModal()}>
          Close
        </Button>
      </div>
    </div>
  );
}
