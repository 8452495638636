import http from "./http.service";
import config from "./constant";
import FileSaver from "file-saver";
import { store } from "../store";
import { SET_VENDOR_LIST } from "../store/constants";
const getPartnerList = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(
      config.TradingPartner.partnerList,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const allVendorList = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(
      config.TradingPartner.allVendorList,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAllVendorInfo = async (params) => {
  try {
    const { app } = store.getState();
    console.log(app);
    let reqParams = {
      poSearch: true,
    };
    const response = await http.get(
      config.TradingPartner.vendorInfoList,
      reqParams
    );
    return { status: response.status, data: response.data || [] };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getVendorInfo = async (params) => {
  try {
    const { app } = store.getState();
    console.log(app);
    let reqParams = {
      ...params,
    };
    if (app?.vendorList?.length === 0) {
      const response = await http.get(
        config.TradingPartner.vendorInfoList,
        reqParams
      );
      store.dispatch({
        type: SET_VENDOR_LIST,
        payload: response.data,
      });
      return { status: response.status, data: response.data || [] };
    } else {
      return { status: 200, data: app?.vendorList || [] };
    }
  } catch (err) {
    return Promise.reject(err);
  }
};

const addPartner = async (params) => {
  try {
    const response = await http.post(config.TradingPartner.addPartner, params);
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const validatePartner = async (params) => {
  try {
    const response = await http.post(
      config.TradingPartner.validatePartner,
      params
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const modifyPartner = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.put(
      config.TradingPartner.modifyPartner,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getDetailsByPartnerID = async (partnerId) => {
  try {
    const response = await http.get(
      `${config.TradingPartner.getDetailsByPartnerID}/${partnerId}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getDetailsByPartnerName = async (partnerName) => {
  try {
    const response = await http.get(
      `${config.TradingPartner.getDetailsByPartnerName}/${partnerName}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const addBusinessUnits = async (reqParams, partnerId) => {
  try {
    const response = await http.post(
      `/admin/tradingpartner/${partnerId}/bus-units`,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getBusinessUnits = async (partnerId) => {
  try {
    const response = await http.get(
      `/admin/tradingpartner/${partnerId}/bus-units/list`,
      {}
    );
    return { status: response.status, data: response.data || [] };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getActiveBusinessUnits = async (partnerId) => {
  try {
    const response = await http.get(
      `/admin/tradingpartner/${partnerId}/bus-units/list/active`,
      {}
    );
    return { status: response.status, data: response.data || [] };
  } catch (err) {
    return Promise.reject(err);
  }
};
const addAttachment = async (partnerId, params) => {
  try {
    const response = await http.post(
      `/admin/tradingpartner/${partnerId}/attachments`,
      params
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getAttachments = async (partnerId) => {
  try {
    const response = await http.get(
      `/admin/tradingpartner/${partnerId}/attachments/list`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const addNotifications = async (partnerId, notifications) => {
  try {
    const response = await http.post(
      `/admin/tradingpartner/${partnerId}/notifications`,
      notifications
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getNotifications = async (partnerId) => {
  try {
    const response = await http.get(
      `/admin/tradingpartner/${partnerId}/notifications/list`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getPartnerDetails = async (params) => {
  try {
    const response = await http.post(
      config.TradingPartner.getPartnerDetails,
      params
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const uploadAttachment = async (formData) => {
  try {
    const response = await http.post(
      config.TradingPartner.addAttachment,
      formData,
      { "content-type": "multipart/form-data" }
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const getCustomerList = async () => {
  try {
    const response = await http.get(
      `${config.Logistics.getCustomersLoadSummary}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const exportTPList = async (params) => {
  try {
    const fileName = "Trading Partner";
    const response = await http.post(
      config.TradingPartner.exportPartnerList,
      params,
      {},
      {
        responseType: "blob",
      }
    );
    // showXlsxFile(response.data, params.uploadId);
    FileSaver.saveAs(response.data, `${fileName}.${params.fileType}`);
    return { status: response.status, data: [] };
  } catch (err) {
    return Promise.reject(err);
  }
};
const partnerRelationShipBulkUpload = async (formData) => {
  try {
    const response = await http.post(
      config.TradingPartner.tprBulkUpload,
      formData,
      { "content-type": "multipart/form-data" }
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};

const partnerRelationShipUploadSummary = async (params) => {
  try {
    let reqParams = {
      ...params,
    };
    const response = await http.get(
      config.TradingPartner.tprUploadSummary,
      reqParams
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const partnerRelationShipErrorFileDownload = async (params) => {
  try {
    const response = await http.get(
      `/admin/tradingpartner/${params.uploadId}/tpRelationErrorDownload`,
      {},
      {},
      {
        responseType: "blob",
      }
    );

    FileSaver.saveAs(response.data, "Tp_RelationShip-Error-template.xlsx");
    return { status: response.status, data: [] };
  } catch (err) {
    return Promise.reject(err);
  }
};
const getCarrierDetails = async (params) => {
  try {
    const response = await http.get(
      `${config.TradingPartner.getCarrierDetails}`,
      {}
    );
    return { status: response.status, data: response.data };
  } catch (err) {
    return Promise.reject(err);
  }
};
const TradingPartnerService = {
  getPartnerList,
  addPartner,
  addBusinessUnits,
  getBusinessUnits,
  addAttachment,
  getAttachments,
  addNotifications,
  getNotifications,
  getPartnerDetails,
  getDetailsByPartnerID,
  getDetailsByPartnerName,
  modifyPartner,
  uploadAttachment,
  validatePartner,
  getVendorInfo,
  getAllVendorInfo,
  getCustomerList,
  getActiveBusinessUnits,
  exportTPList,
  partnerRelationShipBulkUpload,
  partnerRelationShipUploadSummary,
  partnerRelationShipErrorFileDownload,
  getCarrierDetails,
  allVendorList,
};

export default TradingPartnerService;
